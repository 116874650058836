<template>
	<ProTemplate>
		<template v-slot:informacion>
			<v-list>
				<v-list-item class="px-2">
					<v-list-item-avatar color="primary">
						<v-icon class="primary" dark>
							mdi-account
						</v-icon>
					</v-list-item-avatar>
					<v-fade-transition mode="out-in">
						<v-list-item-content>
							<v-list-item-title class="text-h6 primary--text" v-if="!mini">
								Información
							</v-list-item-title>
							<v-list-item-subtitle>
								<v-tooltip right color="primary">
									<template v-slot:activator="{on}">
										<v-btn v-on="on" small class="mr-2 my-auto" outlined @click="nuevo()">nuevo
										</v-btn>
									</template>
									<span>ctrl+a</span>
								</v-tooltip>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-fade-transition>
				</v-list-item>
			</v-list>
			<template v-if="rol === 'sudo' && firebaseId">
				<div class="pl-5">
					<v-row no-gutters>
						<v-col>
							fb_id: {{ firebaseId }}
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col>
							int_id: {{ interview }}
						</v-col>
					</v-row>
				</div>

			</template>

			<v-form ref="datosGenerales">
				<v-list nav>
					<v-list-item>
						<v-list-item-icon>
							<v-icon color="secondary">fal fa-file-heart</v-icon>
						</v-list-item-icon>
						<v-list-item-title>
							<v-text-field label="Número folio" v-model="folio"
							              hide-details
							              append-outer-icon="fal fa-search"
							              @click:append-outer="buscarFolio"/>
						</v-list-item-title>
					</v-list-item>
					<v-list-item>
						<v-list-item-icon>
							<v-tooltip right>
								<template v-slot:activator="{on}">
									<v-btn v-on="on" icon @click="scanId">
										<v-icon color="secondary">mdi-card-account-details</v-icon>
									</v-btn>
								</template>
								Escanear cédula
							</v-tooltip>

						</v-list-item-icon>
						<v-list-item-title>
							<v-row no-gutters class="mt-4">
								<v-col cols="12" v-if="firebaseId">
									<v-text-field label="Fecha de evaluación" :value="calcDate(evaluationDate)" readonly
									              dense/>
								</v-col>
								<v-col cols="4">
									<v-select :items="['CC','TI/NUIP','CE','PP','NIT']" label="Tipo id"
									          v-model="informacion.tipoIdentificacion" required
									          :rules="[rules.required]" :readonly="terminada" hide-details/>
								</v-col>
								<v-col cols="8">
									<v-text-field ref="identificacion" label="Número Identificación" hide-details
									              v-model="informacion.identificacion" required
									              :rules="[rules.required]"
									              :append-outer-icon="permisos.includes('consulta')?'fal fa-search':null"
									              @click:append-outer="buscar"/>
								</v-col>
							</v-row>
						</v-list-item-title>
					</v-list-item>
					<!--					<v-list-item>-->
					<!--						<v-list-item-icon>-->
					<!--							<v-icon color="secondary">mdi-account-multiple</v-icon>-->
					<!--						</v-list-item-icon>-->
					<!--						<v-list-item-title>-->
					<!--							<v-row no-gutters class="mt-4">-->
					<!--								<v-col cols="6">-->
					<!--									<v-text-field label="Primer nombre" v-model="informacion.primerNombre" required-->
					<!--									              :rules="[rules.required]" :readonly="terminada" dense/>-->
					<!--								</v-col>-->
					<!--								<v-col cols="6">-->
					<!--									<v-text-field label="Segundo nombre" v-model="informacion.segundoNombre"-->
					<!--									              :readonly="terminada" dense/>-->
					<!--								</v-col>-->
					<!--								<v-col cols="6">-->
					<!--									<v-text-field label="Primer apellido" hide-details-->
					<!--									              v-model="informacion.primerApellido" required-->
					<!--									              :rules="[rules.required]" :readonly="terminada" dense/>-->
					<!--								</v-col>-->
					<!--								<v-col cols="6">-->
					<!--									<v-text-field label="Segundo apellido" hide-details-->
					<!--									              v-model="informacion.segundoApellido" :readonly="terminada" dense/>-->
					<!--								</v-col>-->
					<!--							</v-row>-->


					<!--						</v-list-item-title>-->
					<!--					</v-list-item>-->
					<!--					<v-list-item>-->
					<!--						<v-list-item-icon>-->
					<!--							<v-icon color="secondary">mdi-calendar-month</v-icon>-->
					<!--						</v-list-item-icon>-->
					<!--						<v-list-item-title>-->
					<!--							<v-row no-gutters class="mt-4">-->
					<!--								<v-col cols="6">-->
					<!--									<v-menu ref="menuFechaNacimiento" v-model="menuFechaNacimiento"-->
					<!--									        :close-on-content-click="false"-->
					<!--									        transition="scale-transition"-->
					<!--									        offset-y-->
					<!--									        min-width="auto" :readonly="terminada"-->
					<!--									>-->
					<!--										<template v-slot:activator="{ on, attrs }">-->
					<!--											<v-text-field v-model="informacion.fechaNacimiento" :readonly="terminada"-->
					<!--											              label="Fecha de Nacimiento"-->
					<!--											              readonly-->
					<!--											              v-bind="attrs"-->
					<!--											              v-on="on"-->
					<!--											></v-text-field>-->
					<!--										</template>-->
					<!--										<v-date-picker-->
					<!--											v-model="informacion.fechaNacimiento"-->
					<!--											:active-picker.sync="activePicker"-->
					<!--											:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"-->
					<!--											min="1920-01-01"-->
					<!--											@change="save"-->
					<!--										></v-date-picker>-->
					<!--									</v-menu>-->
					<!--								</v-col>-->
					<!--								<v-col cols="6">-->
					<!--									<v-text-field label="Edad (años)" hide-details="auto"-->
					<!--									              v-model.number="informacion.edad" disabled readonly-->
					<!--									              :rules="[rules.required, v => v>=2||'Solo mayores de 2 años']"/>-->
					<!--								</v-col>-->
					<!--							</v-row>-->

					<!--						</v-list-item-title>-->


					<!--					</v-list-item>-->
					<v-list-item>
						<v-list-item-icon>
							<v-icon color="secondary">far fa-venus-mars</v-icon>
						</v-list-item-icon>
						<v-list-item-title>
							<v-row no-gutters class="mt-4">
								<v-col cols="6">
									<v-select :items="[{value:'f',text:'Femenino'},{value:'m',text:'Masculino'}]"
									          label="Sexo"
									          hide-details v-model="informacion.sexo" required :rules="[rules.required]"
									          :readonly="terminada"/>
								</v-col>
								<v-col cols="6">
									<v-text-field label="Edad (años)" hide-details="auto"
									              v-model.number="informacion.edad"
									              :rules="[rules.required, v => v>=2||'Solo mayores de 2 años']"
									/>
								</v-col>
							</v-row>

						</v-list-item-title>
					</v-list-item>
				</v-list>
				<v-divider/>
				<v-btn small class="mb-1" block outlined @click="iniciar()" v-if="!mini && !terminada">
					iniciar/reiniciar
				</v-btn>
				<v-divider/>

				<v-list>
					<v-list-item class="px-2">
						<v-list-item-avatar class="my-0">
							<v-icon class="primary" dark>
								mdi-clipboard-pulse-outline
							</v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title class="text-h6 primary--text" v-if="!mini">
								Signos Vitales
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>

				<v-list>
					<v-list dense>
						<v-list-item>
							<v-list-item-icon>
								<v-icon color="secondary">far fa-head-side-brain</v-icon>
							</v-list-item-icon>
							<v-list-item-title>
								<v-select :items="avpus" label="Nivel de Consciencia (AVPU)" solo hide-details
								          v-model="signoVitales.avpu" :readonly="terminada"/>
							</v-list-item-title>
						</v-list-item>
					</v-list>
					<v-list-item>
						<v-list-item-icon>
							<v-icon color="secondary">far fa-heartbeat</v-icon>
						</v-list-item-icon>
						<v-list-item-title>
							<v-row>
								<v-col cols="6">
									<v-text-field label="Frec. Cardiaca (ppm)" hide-details
									              v-model.number="signoVitales.frecuencia_cardiaca" type="number"
									              :readonly="terminada"/>
								</v-col>
								<v-col cols="6">
									<v-text-field label="Temperatura (C)" hide-details
									              v-model.number="signoVitales.temperatura" type="number"
									              :readonly="terminada">
										<template v-slot:prepend>
											<v-icon color="secondary" class="mx-2" style="top: -9px">far
												fa-thermometer-full
											</v-icon>
										</template>
									</v-text-field>
								</v-col>
							</v-row>
						</v-list-item-title>
					</v-list-item>

					<v-list-item>
						<v-list-item-icon>
							<v-icon color="secondary">far fa-lungs</v-icon>
						</v-list-item-icon>
						<v-list-item-title>
							<v-row>
								<v-col cols="6">
									<v-text-field label="Respiración (resp/min)" hide-details
									              v-model.number="signoVitales.frecuencia_respiratoria" type="number"
									              :readonly="terminada"/>
								</v-col>
								<v-col cols="6">
									<v-text-field label="Saturación (SpO2)" hide-details
									              v-model.number="signoVitales.saturacion" type="number"
									              :readonly="terminada">
										<template v-slot:prepend>
											<v-icon color="secondary" style="top: -9px">far fa-leaf-heart</v-icon>
										</template>
									</v-text-field>
								</v-col>

							</v-row>
						</v-list-item-title>
					</v-list-item>

					<v-list-item>
						<v-list-item-icon>
							<v-icon color="secondary">far fa-heart</v-icon>
						</v-list-item-icon>
						<v-list-item-title>
							<v-row>
								<v-col cols="6">
									<v-text-field label="Sistólica (mm Hg)" hide-details
									              v-model.number="signoVitales.sistolica" type="number"
									              :readonly="terminada"/>
								</v-col>
								<v-col cols="6">
									<v-text-field label="Diastólica (mm Hg)" hide-details
									              v-model.number="signoVitales.diastolica" type="number"
									              :readonly="terminada">
										<template v-slot:prepend>
											<v-icon color="secondary" class="mx-1" style="top: -9px">far
												fa-heart-circle
											</v-icon>
										</template>
									</v-text-field>
								</v-col>
							</v-row>
						</v-list-item-title>
					</v-list-item>

					<v-list-item>
						<v-list-item-icon>
							<v-icon color="secondary">far fa-person-arrow-up-from-line</v-icon>
						</v-list-item-icon>
						<v-list-item-title>
							<v-row>
								<v-col cols="6">
									<v-text-field label="Altura (cm)" hide-details v-model.number="signoVitales.altura"
									              type="number" :readonly="terminada"/>
								</v-col>
								<v-col cols="6">
									<v-text-field label="Peso (kg)" hide-details v-model.number="signoVitales.peso"
									              type="number" :readonly="terminada">
										<template v-slot:prepend>
											<v-icon color="secondary" style="top: -9px">far fa-weight-scale</v-icon>
										</template>
									</v-text-field>
								</v-col>

							</v-row>
						</v-list-item-title>
					</v-list-item>
					<v-list-item>
						<v-list-item-icon>
							<v-icon color="secondary">far fa-person-rays</v-icon>
						</v-list-item-icon>
						<v-list-item-title>
							<v-row>
								<v-col>
									<v-text-field label="IMC (cálculo automático)" hide-details
									              :value="parseFloat(imc)?.toFixed(2)"
									              type="number" readonly/>
								</v-col>
							</v-row>
						</v-list-item-title>
					</v-list-item>
				</v-list>

				<v-divider/>

				<v-list dense>
					<v-list-item class="px-2">
						<v-list-item-avatar color="primary" class="my-0">
							<v-icon class="primary" dark>
								far fa-box-full
							</v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-fade-transition mode="out-in">
								<v-list-item-title class="text-h6 primary--text" v-if="!mini">
									Recursos requeridos
								</v-list-item-title>
							</v-fade-transition>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list dense>
					<v-list-item>
						<v-list-item-icon>
							<v-icon color="secondary">far fa-sort-numeric-up-alt</v-icon>
						</v-list-item-icon>
						<v-list-item-title>
							<v-select :items="recursos" label="Cantidad de recursos" solo hide-details
							          v-model="signoVitales.recursos" :readonly="terminada"/>
						</v-list-item-title>
					</v-list-item>
				</v-list>
				<v-divider/>
			</v-form>
		</template>

		<template v-slot:signosVitalesInicio v-if="permisos.includes('evaluar')">
			<v-btn block outlined @click="analizarSignos">analizar signos {{ esi ? "(ESI)" : "(NO ESI)" }}</v-btn>
		</template>

		<template v-slot:selectorsintomas>
			<v-list>
				<v-list-item class="px-2">
					<v-list-item-avatar color="primary" :class="{'mr-0': miniSintomas}">
						<span class="white--text text-h5">2</span>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title class="text-h6 primary--text">
							<div v-if="!miniSintomas">
								<span>Identificador de Síntomas</span>
								<v-tooltip left color="primary">
									<template v-slot:activator="{on}">
										<v-btn @click="sintomasState()" v-on="on" icon right absolute>
											<v-icon color="primary">fad fa-chevron-double-left</v-icon>
										</v-btn>
									</template>
									<span>Minimizar</span>
								</v-tooltip>
							</div>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<div style="height: calc(100vh - 140px)" class="overflow-y-auto px-2" v-if="!miniSintomas">
				<v-expansion-panels accordion multiple tile>
					<!--LISTA DE SINTOMAS-->
					<v-expansion-panel>
						<v-expansion-panel-header>
							<div class="text-h6 secondary--text">Condiciones, Factores de Riesgo y Síntomas</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-row>
								<v-col cols="7">
									<v-text-field v-model="search" clearable hide-details
									              prepend-inner-icon="mdi-magnify" label="Buscar" dense/>
								</v-col>
								<v-col cols="5" class="align-end d-flex">
									<div class="d-flex align-end">
										<span class="mr-3">Filtrar: </span>
										<v-btn-toggle v-model="filterItem" shaped>
											<v-btn small value="Condicion">C</v-btn>
											<v-btn small value="Factor de riesgo">F</v-btn>
											<v-btn small value="Sintoma">S</v-btn>
										</v-btn-toggle>
									</div>

								</v-col>
							</v-row>

							<v-sheet>
								<v-data-table :headers="headers" :items="filterItems" :search="search"
								              :items-per-page="5" @click:row="addSintoma($event.id)"
								              :custom-filter="customSearch">
									<template #item.name="{item}">
										{{ localDev() ? item.id + " - " : null }}{{ item.name }}
									</template>
								</v-data-table>
							</v-sheet>
						</v-expansion-panel-content>
					</v-expansion-panel>

					<!--BUSCAR SINTOMAS-->
					<v-expansion-panel :disabled="!interview">
						<v-expansion-panel-header>
							<div class="text-h6" :class="{'secondary--text':interview}">Recomendar Síntomas</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<buscador-sintomas :interview="interview" fullscreen @input="addSintoma($event)"/>
						</v-expansion-panel-content>
					</v-expansion-panel>

					<!--FACTORES DE RIESGO SUGERIDOS-->
					<v-expansion-panel>
						<v-expansion-panel-header>
							<div class="text-h6 secondary--text">Factores de Riesgo Sugeridos</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<risk-factor :riskfactors="factoresRiesgoList" fullscreen
							             @input="addMultipleSintomas($event)"/>
						</v-expansion-panel-content>
					</v-expansion-panel>

					<!--RUTAS-->
					<v-expansion-panel>
						<v-expansion-panel-header>
							<div class="text-h6 secondary--text">Sugerencia de Rutas</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-btn v-for="(ruta,n) in rutas" :key="n" outlined block class="my-1"
							       :disabled="ruta.disabled"
							       @click="rutaComponent=ruta.component; rutaDialog=true">
								{{ ruta.text }}
							</v-btn>
						</v-expansion-panel-content>
					</v-expansion-panel>

					<!--MAS FRECUENTES-->
					<v-expansion-panel>
						<v-expansion-panel-header>
							<div class="text-h6 secondary--text">Síntomas más frecuentes</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-row>
								<v-col cols="7">
									<v-text-field v-model="search" clearable hide-details
									              prepend-inner-icon="mdi-magnify" label="Buscar" dense/>
								</v-col>
								<v-col cols="5" class="align-end d-flex">
									<div class="d-flex align-end">
										<span class="mr-3">Filtrar: </span>
										<v-btn-toggle v-model="filterItem" shaped>
											<v-btn small value="Condicion">C</v-btn>
											<v-btn small value="Factor de riesgo">F</v-btn>
											<v-btn small value="Sintoma">S</v-btn>
										</v-btn-toggle>
									</div>
								</v-col>
							</v-row>
							<v-sheet>
								<v-data-table :headers="headers" :items="filterCommonItems" :search="search"
								              :items-per-page="5" @click:row="addSintoma($event.id)"
								              :custom-filter="customSearch"/>
							</v-sheet>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>

				<v-dialog v-model="rutaDialog" width="auto">
					<component :is="rutaComponent" @input="addMultipleSintomas($event); rutaDialog = false"></component>
				</v-dialog>
			</div>

			<div class="fill-height" style="top: -70px;position:relative;" @click="sintomasState()"
			     v-if="miniSintomas"/>
		</template>

		<!--	SINTOMAS	-->
		<template v-slot:sintomas>
			<v-list class="py-0">
				<v-list-item class="px-2">
					<v-list-item-avatar color="primary">
						<span class="white--text text-h5">{{ terminada ? 2 : 3 }}</span>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title class="text-h6 primary--text text-wrap">
							Confirmación<br>de Síntomas
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>


			<div style="height: calc(100vh - 200px)" class="overflow-y-auto">
				<v-list class="overflow-y-auto">
					<v-list-item class="px-2" v-for="(ev,n) in filteredEvidence" dense :key="`symptom_${n}`"
					             v-if="_.find(items,['id', ev.id])">
						<v-list-item-content>
							<v-list-item-title class="text-wrap">
								<div style="max-width: 130px">
									{{ ev.source === 'initial' ? '* ' : '' }}{{ _.find(items, ["id", ev.id]).name }}
									{{
										ev.choice_id === "absent" ? " - No" : ev.choice_id === "unknown" ? ' - No sabe' : ocultarNo ? "" : " - Si"
									}}

								</div>
							</v-list-item-title>
						</v-list-item-content>
						<v-list-item-action class="ml-2" v-if="!terminada">
							<v-btn icon @click="deleteSintoma(ev)">
								<v-icon color="grey lighten-1">mdi-trash-can-outline</v-icon>
							</v-btn>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</div>
		</template>

		<template v-slot:botonesSintomas v-if="permisos.includes('evaluar')">
			<div>* Síntomas de consulta</div>
			<v-item-group class="d-flex justify-space-around mt-1">
				<v-tooltip top>
					<template v-slot:activator="{on}">
						<v-btn v-on="on" outlined small @click="ocultarNo=!ocultarNo">
							<v-icon>fal fa-user-{{ ocultarNo ? 'check' : 'times' }}</v-icon>
						</v-btn>
					</template>
					<span>{{ `Mostrar ${ocultarNo ? 'TODAS respuestas' : 'solo respuestas SI'}` }}</span>
				</v-tooltip>

				<v-tooltip top v-if="!terminada">
					<template v-slot:activator="{on}">
						<v-btn v-on="on" outlined small @click="limpiarSintomas()">
							<v-icon>fal fa-trash</v-icon>
						</v-btn>
					</template>
					<span>Limpiar</span>
				</v-tooltip>
				<v-tooltip top v-if="!terminada || rol=='sudo'">
					<template v-slot:activator="{on}">
						<v-btn v-on="on" outlined small @click="sugerirSintomas()" :disabled="diagnosticarDisabled">
							<v-icon>fal fa-play</v-icon>
						</v-btn>
					</template>
					<span>Iniciar diagnóstico</span>
				</v-tooltip>
			</v-item-group>
		</template>

		<template v-slot:preguntas>

			<v-row no-gutters>
				<v-col cols="12">
					<v-list>
						<v-list-item class="px-2">
							<v-list-item-avatar color="primary">
								<span class="white--text text-h5">4</span>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title class="text-h6 primary--text text-wrap">
									Preguntas diagnósticas
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-col>
				<v-col cols="12">
					<cuestionario v-if="pregunta" :start="pregunta" :interview="interview" fullscreen></cuestionario>
				</v-col>
			</v-row>

		</template>

		<template v-slot:resultados>
			<v-list>
				<v-list-item class="px-2">
					<v-list-item-avatar color="primary">
						<span class="white--text text-h5">{{ terminada ? 3 : 5 }}</span>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title class="text-h6 primary--text text-wrap">
							Resultados preliminares
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-container class="overflow-x-hidden">
				<template v-if="!_.isEmpty(triage.triage_level)">
					<div class="text-h6 secondary--text">Nivel de Triage:</div>
					{{ mensajes[triage.triage_level].triage }}
					<div class="text-h6 secondary--text">Nombre triage:</div>
					{{ mensajes[triage.triage_level].name }}
					<div class="text-h6 secondary--text">Aplica teleconsulta:</div>
					{{ triage.teleconsultation_applicable ? "Si" : "No" }}
					<div class="text-h6 secondary--text">Recomendación del sistema para el paciente:</div>
					{{ triage.triage_level ? mensajes[triage.triage_level].todo : '' }}
				</template>

				<template v-if="pregunta">
					<div class="text-h6 secondary--text">Diagnósticos</div>

					<v-card v-for="(dx, n) in pregunta.conditions.slice(0,5)" :key="`dx_${n}`" class="mb-2">
						<v-card-text>
							<div>{{ dx.name }}{{ dx.name !== dx.common_name ? ` (${dx.common_name})` : "" }}</div>
							<div>Probabilidad: {{ Math.floor(dx.probability * 100) }}%</div>
							<div>Recomendaciones: {{ dx.recomendación }}</div>
						</v-card-text>
					</v-card>
				</template>

				<template v-if="!_.isEmpty(triage.serious)">
					<div class="text-h6 secondary--text">Síntomas serios</div>
					<v-card v-for="(sint, n) in triage.serious" :key="`sint_${n}`" class="mb-2">
						<v-card-text>
							<div>
								{{
									sint.name
								}}{{
									sint.common_name && sint.name !== sint.common_name ? ` (${sint.common_name})` : ""
								}}
							</div>
							<div>Es emergencia: {{ sint.is_emergency ? "Si" : "No" }}</div>
						</v-card-text>
					</v-card>
				</template>

				<template v-if="especialista">
					<div class="text-h6 secondary--text">Especialista recomendado</div>
					<v-card>
						<v-card-text>
							<div>{{ especialista.recommended_specialist.name }}</div>
							<div>Canal: {{ getCanalEspecialista[especialista.recommended_channel] }}</div>
						</v-card-text>
					</v-card>
				</template>

				<template v-if="terminada && sugerenciaFinal">
					<div class="text-h6 secondary--text">Sugerencia Final al paciente</div>
					<v-card>
						<v-card-text>
							<div>{{ sugerenciaFinal }}</div>
						</v-card-text>
					</v-card>
				</template>

				<template v-if="filteredEvidence.length">
					<v-card>
						<v-card-text>
							<div>
								Por favor seleccione la sede si desea enviar al paciente a la sala de urgencias
							</div>
							<div class="d-flex">
								<v-select v-model="selectedSede" :items="sedes" item-text="nombre" item-value="id"
								          class="flex-grow-1"
								/>

								<v-tooltip bottom color="primary">
									<template v-slot:activator="{on}">
										<v-btn color="primary" icon v-on="on" fab
										       :disabled="!!!selectedSede"
										       @click="enviar_urgencias" style="right: 10px">
											<v-icon>far fa-ambulance</v-icon>
										</v-btn>
									</template>
									<span>Enviar a sala urgencias</span>
								</v-tooltip>
							</div>
						</v-card-text>

					</v-card>


				</template>

			</v-container>
		</template>

		<template v-slot:sintomaSugerido>
			<v-dialog v-model="sugerirSintomaDialog">
				<v-form v-model="sugerirSintomasForm" ref="sugerirSintomasForm">
					<v-card>
						<v-card-title>
							Síntomas adicionales sugeridos
							<v-spacer/>
							<v-icon color="red" @click="sugerirSintomaDialog=false">far fa-times</v-icon>
						</v-card-title>
						<v-card-subtitle>
							<div>¿Presenta alguno de los siguientes síntomas?</div>
						</v-card-subtitle>
						<v-card-text>
							<v-row no-gutters>
								<template v-for="(sintoma,n) in sintomasSugeridos">
									<v-col cols="12" md="6">
										<div class="font-weight-bold">{{ sintoma.name }}
											{{
												sintoma.name !== sintoma.common_name ? '(' + sintoma.common_name + ')' : ''
											}}
										</div>

										<v-radio-group :rules="[rules.required]" row dense hide-details
										               class="mt-0 mb-5"
										               v-model="sintomasSugeridosList[n]">
											<v-radio label="Si"
											         :value="{id: sintoma.id, choice_id: 'present'}"></v-radio>
											<v-radio label="No sabe"
											         :value="{id: sintoma.id, choice_id: 'unknown'}"></v-radio>
											<v-radio label="No"
											         :value="{id: sintoma.id, choice_id: 'absent'}"></v-radio>
										</v-radio-group>
									</v-col>
								</template>
							</v-row>
						</v-card-text>
						<v-card-actions>
							<v-spacer/>
							<v-btn @click="addSintomasSugerido()" outlined>confirmar</v-btn>
						</v-card-actions>
					</v-card>
				</v-form>

			</v-dialog>
		</template>

		<template v-slot:PDF>
			<v-dialog v-model="finalizarDialog">
				<v-form ref="finalizarForm" v-model="finalizarForm">
					<v-card>
						<v-card-text>
							<div class="text-h6 primary--text">
								Por favor ingrese el número de caso/folio
							</div>

<!--							<div class="text-h6">Para finalizar la encuesta, por favor, escriba cual fue la sugerencia-->
<!--								final que se le dio al paciente:-->
<!--							</div>-->
<!--							<v-textarea rows="3" counter solo dense v-model="sugerenciaFinal"-->
<!--							            :rules="[v=>v.length>20||'La recomendación final no puede ser mas corta a 30 caracteres']"/>-->

							<v-text-field solo dense v-model="sugerenciaFinal" :rules="[v=>!!v||'El número de folio es obligatorio']"/>
						</v-card-text>
						<v-card-actions>
							<v-spacer/>
							<v-btn @click="finalizar" outlined color="primary">Grabar</v-btn>
						</v-card-actions>
					</v-card>
				</v-form>
			</v-dialog>


			<v-tooltip top color="primary" v-if="terminada">
				<template v-slot:activator="{on}">
					<v-btn color="primary" icon v-on="on" absolute right fab @click="createPDF" style="right: 55px"
					       :disabled="_.isEmpty(triage.triage_level)">
						<v-icon>far fa-file-pdf</v-icon>
					</v-btn>
				</template>
				<span>PDF</span>
			</v-tooltip>
			<v-tooltip top color="primary" v-else>
				<template v-slot:activator="{on}">
					<v-btn color="primary" outlined v-on="on" absolute right @click="finalizarDialog=true"
					       style="right: 55px" :disabled="_.isEmpty(triage.triage_level)">
						<v-icon class="mr-3">far fa-save</v-icon>
						Finalizar
					</v-btn>
				</template>
				<span>Finalizar</span>
			</v-tooltip>
		</template>

		<template v-slot:consultaResultados>
			<v-dialog v-model="consultaResultados">
				<v-card>
					<v-card-title>Resultados de búsqueda de cédula</v-card-title>
					<v-card-text>
						<v-data-table :headers="resultadosHeaders" :items="resultados" class="elevation-1"
						              :show-expand="permisos.includes('diagnostico')" single-expand>
							<template v-slot:item.date="{ item }">
								{{ calcDate(item.date) }}
								<div v-if="item.source_sv">Toma signos vitales: {{ item.source_sv }}</div>
								<div v-if="!item.id" style="color: red">Sin iniciar entrevista</div>
							</template>
							<template v-slot:item.terminada="{ item }">
								<v-avatar>
									<v-icon :color="item.terminada?'green':'info'">far
										fa-{{ item.terminada ? 'check' : 'times' }}
									</v-icon>
								</v-avatar>
							</template>
							<template v-slot:item.triage="{ item }">
								<v-chip :color="item.triage ? getTriage[item.triage.triage_level].color : 'info'" dark>
									{{ item.triage ? getTriage[item.triage.triage_level].level : "N/D" }}
								</v-chip>
							</template>
							<template v-slot:item.especialista="{ item }">
								{{ item.especialista ? item.especialista.recommended_specialist.name : 'N/D' }}
							</template>
							<template v-slot:item.load="{ item }">
								<v-btn icon @click="loadUserData(item)">
									<v-icon color="accent">fad fa-user-md-chat</v-icon>
								</v-btn>
							</template>

							<template v-slot:expanded-item="{ headers, item }">
								<td :colspan="resultadosHeaders.length"
								    v-if="item.diagnosticos && item.diagnosticos.length>0">
									<v-container>
										<h3 class="accent--text">Posibles diagnósticos</h3>
										<v-row v-for="(syntom,n) in item.diagnosticos" no-gutters class="my-2"
										       align="center" :key="`syntom_${n}`">
											<v-col cols="auto">
												<v-icon class="mr-5" color="blue">fad fa-exclamation-circle</v-icon>
											</v-col>
											<v-col>{{ syntom.name }} - {{ _.round(syntom.probability * 100) }}%</v-col>
											<v-col cols="12">
												<v-divider class="mt-1"/>
											</v-col>
										</v-row>
									</v-container>
								</td>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-dialog>
		</template>

		<template v-slot:scanIdDialog>
			<v-dialog v-model="scanIdDialog" width="400" persistent>
				<v-card>
					<v-form>
						<v-card-title>
							<h3>Escaneé la cédula</h3>
							<v-spacer/>
							<v-btn @click="cancelScan()" text>X</v-btn>
						</v-card-title>
						<v-card-subtitle>
							<small class="red--text">ADVERTENCIA: La función de escanear cédula no sirve con la nueva
								cédula digital</small>
						</v-card-subtitle>
						<template v-if="false">
							<v-card-text>
								<h5 class="mb-4">Verifique los datos escaneados</h5>
								<v-row>
									<v-col cols="12">
										<v-text-field label="Identificación" v-model.number="idCheck.identificacion"
										              type="number" outlined hide-details dense/>
									</v-col>
									<v-col cols="12">
										<v-text-field label="Nombre" v-model="idCheck.nombre" outlined hide-details
										              dense/>
									</v-col>
									<v-col cols="6">
										<v-text-field label="Sexo" v-model="idCheck.sexo" outlined hide-details dense/>
									</v-col>
									<v-col cols="6">
										<v-text-field label="Edad" v-model.number="idCheck.edad" type="number" outlined
										              hide-details dense/>
									</v-col>
								</v-row>
							</v-card-text>
							<v-card-actions>
								<v-spacer/>
								<v-btn @click="confirmScanId" outlined color="green">confirmar</v-btn>
								<v-btn @click="scanId" outlined color="orange">reintentar</v-btn>
							</v-card-actions>
						</template>
					</v-form>
				</v-card>
			</v-dialog>
		</template>
	</ProTemplate>
</template>

<script>
import ProTemplate from "@/views/layouts/ProTemplate";
import buscardorSintomas from "@/components/buscardorSintomas";
import riskFactor from "@/components/riskFactor";
import cuestionario from "@/components/cuestionario";
import {mapActions, mapGetters} from "vuex";
import {
	API_URL,
	CONCEPTOS,
	INTERVIEW,
	SIGNOSVITALES,
	EVIDENCE,
	REMOVE_EVIDENCE,
	START_INTERVIEW,
	CLEAN_ALL_EVIDENCE,
	FIREBASE_ID,
	SET_EVIDENCE,
	PERMISOS,
	EXPRESS,
	ADD_EVIDENCE, ESI, RESET_INTERVIEW, SEDES, ROL
} from "@/store/types";
import BuscadorSintomas from "@/components/buscardorSintomas";
import {analizarSignos, createCollection, fetchOptions, imc, refresh_token, updateCollection} from "@/actions/actions";
import moment from "moment";
import * as types from "@/store/types";
import embarazo from "@/components/rutas/embarazo";
import iras from "@/components/rutas/iras";
import trauma from "@/components/rutas/trauma";
import quemadura from "@/components/rutas/quemadura";
import {mensajesPro} from "../../messages";
import {jsPDF} from "jspdf";
import _ from "lodash";

let controller = {};

export default {
	name: "Pro",
	components: {
		BuscadorSintomas,
		ProTemplate,
		buscardorSintomas,
		cuestionario,
		riskFactor,
		embarazo,
		iras,
		trauma,
		quemadura
	},
	data: () => ({
		selectedSede: null,
		mensajes: mensajesPro,
		informacion: {tipoIdentificacion: "CC"},
		signoVitales: {},
		filterItem: "",
		pregunta: null,
		triage: {serious: []},
		diagnosticos: [],
		especialista: null,

		mini: false,
		miniSintomas: false,
		search: '',
		filter: {},
		diagnosticosDisplay: false,
		rutaDialog: false,
		rutaComponent: "embarazo",

		itemsPerPageArray: [4, 8, 12],
		sortDesc: false,
		page: 1,
		itemsPerPage: 2,
		sortBy: 'name',
		headers: [
			{value: 'name', text: 'Nombre'},
			{value: 'common_name', text: 'Nombre común'},
			{value: 'type', text: 'Tipo', filterable: false, width: '90px'},
		],

		getTriage: {
			emergency_ambulance: {level: "1", color: "red"},
			emergency: {level: "1", color: "deep-orange"},
			consultation_24: {level: "2", color: "amber"},
			consultation: {level: "3", color: "green"},
			self_care: {level: "3", color: "blue"}
		},
		getCanalEspecialista: {
			audio_teleconsultation: "Llamada telefónica",
			video_teleconsultation: "Video llamada",
			text_teleconsultation: "Chat",
			personal_visit: "Presencial",
		},
		avpus: [
			{value: 'a', text: 'A => Alerta'},
			{value: 'v', text: 'V => Verbal'},
			{value: 'p', text: 'P => Dolor'},
			{value: 'u', text: 'U => No responde'}
		],
		recursos: [{value: 0, text: 'Ninguno'}, {value: 1, text: 'Uno'}, {value: 2, text: 'Dos o más'}],
		factoresRiesgoList: [],

		rules: {
			required: v => !!v || 'El campo es obligatorio'
		},

		choiceIds: {
			present: "Si",
			unknown: "No se",
			absent: "No"
		},
		triageResult: false,
		evaluationDate: "",
		ocultarNo: true,
		sugerirSintomaDialog: false,
		sintomasSugeridos: [],
		sintomasSugeridosList: [],
		sugerirSintomasForm: false,
		signosVitalesAnalizados: false,
		resultados: [],
		consultaResultados: false,
		resultadosHeaders: [
			{text: 'Fecha encuesta', align: 'start', value: 'date', width: '250px'},
			{text: 'Nivel triage', align: 'center', value: 'triage'},
			{text: 'Origen', align: 'center', value: 'ubicacion'},
			{text: 'Especialista recomendado', align: 'center', value: 'especialista'},
			{text: 'Terminada', align: 'center', value: 'terminada'},
			{text: 'Cargar datos', align: 'center', value: 'load'},
		],
		ignoreAlerts: false,
		finalizarDialog: false,
		sugerenciaFinal: '',
		finalizarForm: false,
		terminada: false,
		mostCommon: ['s_21', 's_2100', 's_156', 's_13', 's_370', 's_8', 's_309', 's_20', 's_50', 's_1190', 's_53', 's_88', 's_331', 's_284', 's_102', 's_107', 's_329', 's_1483', 's_1547', 's_81'],
		scanIdDialog: false,
		barcode: '',
		idCheck: {
			identificacion: "",
			nombre: "",
			edad: 0,
			sexo: ""
		},
		activePicker: 'YEAR',
		menuFechaNacimiento: false,
		folio: null,
	}),
	async created() {
		this.nuevo(false);
		if (process.env.NODE_ENV === 'development') {
			this.signoVitales = {
				"temperatura": 35.9,
				"frecuencia_cardiaca": 56,
				"frecuencia_respiratoria": 17,
				"saturacion": 97,
				"sistolica": 110,
				"diastolica": 56
			}
		}

		moment.locale('es')
		this.evaluationDate = Date.now();

		Event.$on('mini', val => {
			this.mini = val
		})
		Event.$on('proRespuesta', respuesta => {
			this.addRespuestaDx(respuesta)
		})

	},
	mounted() {
		document.addEventListener('keydown', this.keyListener);
	},
	beforeDestroy() {
		document.removeEventListener("keydown", this.keyListener);
	},
	computed: {
		...mapGetters({
			api_url: API_URL,
			items: CONCEPTOS,
			interview: INTERVIEW,
			criteriosSignosVitales: SIGNOSVITALES,
			evidence: EVIDENCE,
			firebaseId: FIREBASE_ID,
			permisos: PERMISOS,
			express: EXPRESS,
			esi: ESI,
			sedes: SEDES,
			rol: ROL
		}),
		...mapActions({}),
		filterItems() {
			if (!this.filterItem) return this.items
			return this.items.filter(item => item.type === this.filterItem)
		},
		filterCommonItems() {
			return this.items.filter(item => this.mostCommon.includes(item.id))
		},

		filteredEvidence() {
			if (this.ocultarNo) {
				return this.evidence.filter(item => {
					return item.choice_id !== 'unknown' && item.choice_id !== 'absent'
				})
			}
			return this.evidence
		},
		imc() {
			if (this.signoVitales.peso && this.signoVitales.altura)
				return imc(this.signoVitales.peso, this.signoVitales.altura)
		},

		rutas() {
			return [
				{text: 'embarazo', component: 'embarazo', disabled: this.informacion.sexo !== 'f'},
				{text: 'trauma', component: 'trauma'},
				{text: 'IRA\'s / covid', component: 'iras'},
				{text: 'quemadura', component: 'quemadura'},
			]
		},
		diagnosticarDisabled() {
			return this.evidence.length <= 0 || _.isNull(this.interview)
		}
	},

	watch: {
		terminada: function (val) {
			Event.$emit('terminada', val)
		},
		sugerirSintomaDialog: function (val) {
			if (val) this.sintomasSugeridosList = []
		},
		menuFechaNacimiento(val) {
			val && setTimeout(() => (this.activePicker = 'YEAR'))
		}

	},
	methods: {
		...mapActions([START_INTERVIEW, ADD_EVIDENCE, SET_EVIDENCE, REMOVE_EVIDENCE, CLEAN_ALL_EVIDENCE, RESET_INTERVIEW]),

		sintomasState() {
			this.miniSintomas = !this.miniSintomas
			Event.$emit('sintomasState', this.miniSintomas)
		},
		addSintoma(concept_id, choice_id = null) {
			this[ADD_EVIDENCE]({"id": concept_id, "source": "initial", "choice_id": choice_id || "present"})
		},
		addMultipleSintomas(sintomas) {
			sintomas.forEach(item => {
				this.addSintoma(item.id, item.choice_id)
			})
		},
		async addSintomasSugerido() {
			if (this.$refs.sugerirSintomasForm.validate()) {
				this.sugerirSintomaDialog = false
				await this.addMultipleSintomas(this.sintomasSugeridosList)
				this.diagnosticar();
			}
		},
		addRespuestaDx(response) {
			this.pregunta = response.result;
			this.diagnosticos = response.result.conditions.slice(0, 5)
			this.fetchTriage();
		},

		deletePresion(concept_id) {
			const index = _.findIndex(this.evidence, ['id', concept_id])
			if (index >= 0) {
				this.deleteSintoma(concept_id)
			}
		},
		deleteSintoma(symptom) {
			this[REMOVE_EVIDENCE](symptom.id)
		},
		iniciar() {
			if (this.$refs.datosGenerales.validate()) {
				this.limpiarSintomas();
				if (!this.interview) {
					cargando(true);

					const data = {
						age: this.informacion.edad,
						sex: this.informacion.sexo,
						express: this.express
					}
					this.evaluationDate = Date.now()
					fetch(`${this.api_url}interview/start`, fetchOptions({method: 'POST', auth, body: data}))
						.then(response => {
							if (response.ok) return response.json(); else throw response.text();
						})
						.then(response => {
							moment.locale('es-mx');
							createCollection('interviews', {
								admin: {
									id: this.$store.getters[types.USER_ID],
									name: this.$store.getters[types.USER]
								},
								id: response.entrevista,
								user: {...this.informacion},
								date: this.evaluationDate,
								terminada: false,
								fuente: "pro",
							}).then(firebaseId => {
								this[START_INTERVIEW]({
									firebaseId: firebaseId,
									entrevista: response.entrevista,
									evidence: response.evidence,
									age: this.informacion.edad,
									sex: this.informacion.sexo,
									terminada: false
								})

								this.factoresRiesgoList = response.factores_riesgo;

							})

						})
						.catch(e => {
							Promise.resolve(e).then(error => {
								if (JSON.parse(error).message === 'Unauthorized') {
									refresh_token();
									this.iniciar()
								}
							})
						})
						.finally(() => {
							cargando(false)
						})
				} else {
					updateCollection('interviews', this.firebaseId, {
						id: this.interview,
						user: {...this.informacion},
						date: Date.now(),
					})
				}
			}
		},
		async analizarSignos() {
			await analizarSignos(this.signoVitales)
			this.signosVitalesAnalizados = true;
		},
		nuevo(mounted = true) {
			this[RESET_INTERVIEW]();
			this.evaluationDate = "";
			this.informacion = {tipoIdentificacion: 'CC'};
			this.signoVitales = {};
			this.mini = false;
			this.miniSintomas = false;
			this.search = '';
			this.filter = {};
			this.filterItem = "";
			this.pregunta = null;
			this.triage = {serious: []};
			this.diagnosticos = [];
			this.factoresRiesgoList = [];
			this.diagnosticosDisplay = false;
			this.especialista = null;
			this.terminada = false

			if (mounted) this.$refs.identificacion.focus();
		},
		keyListener(e) {
			if (e.key === "a" && (e.ctrlKey || e.metaKey)) {
				e.preventDefault();
				this.nuevo();
			}
		},
		limpiarSintomas() {
			this[CLEAN_ALL_EVIDENCE]()
			this.pregunta = null;
			this.triage = {serious: []};
			this.diagnosticos = [];
			this.especialista = null;
			Event.$emit("clearFactoresRiesgo");
		},
		diagnosticar() {
			cargando(true)
			let data = {
				mode: "diagnosis",
				evidence: this.evidence
			}

			fetch(`${this.api_url}interview/${this.interview}`, fetchOptions({method: 'POST', auth, body: data}))
				.then(response => {
					if (response.ok) return response.json(); else throw response.text();
				})
				.then(response => {
					this.$store.commit(types.MUTATION_EVIDENCE, response.evidence);
					this.$store.dispatch(types.INCREMENT_CALLS);
					this.pregunta = response.result;
					this.diagnosticos = response.result.conditions.slice(0, 5)
					this.fetchTriage();
				})
				.catch(e => {
					Promise.resolve(e).then(error => {
						if (JSON.parse(error).message === 'Unauthorized') {
							refresh_token();
							this.diagnosticar()
						}
					})
				})
				.finally(() => {
					updateCollection('interviews', this.firebaseId,
						{diagnosticos: this.diagnosticos})

					cargando(false)
				})
		},
		fetchTriage() {
			const p1 = fetch(`${this.api_url}interview/${this.interview}`, fetchOptions({
				method: 'POST', auth,
				body: {mode: "triage", evidence: this.evidence}
			}))
				.then(response => {
					if (response.ok) return response.json(); else throw response.text();
				})

			const p2 = fetch(`${this.api_url}interview/${this.interview}`, fetchOptions({
				method: 'POST', auth, body: {
					mode: "recommend_specialist", evidence: this.evidence
				}
			}))
				.then(response => {
					if (response.ok) return response.json(); else throw response.text();
				})

			Promise.all([p1, p2]).then(values => {
				this.triageResult = true
				this.triage = values[0].result
				this.especialista = values[1].result

				updateCollection('interviews', this.firebaseId,
					{
						triage: this.triage,
						especialista: this.especialista,
						sophia: true
					})
			})
				.catch(e => {
					Promise.resolve(e).then(error => {
						if (JSON.parse(error).message === 'Unauthorized') {
							refresh_token();
							this.fetchTriage()
						}
					});
					console.log(e);
				})
		},
		customSearch(value, search, item) {
			return Object.values(item).some(v => v && _.deburr(v.toString()).toLowerCase().includes(_.deburr(search)))
		},
		sugerirSintomas() {
			cargando(true)

			if (this.terminada) {
				cargando(false)
				return this.diagnosticar();
			}


			this.sintomasSugeridos = []
			let data = {
				mode: "suggest",
				evidence: this.evidence
			}

			fetch(`${this.api_url}interview/${this.interview}`, fetchOptions({method: 'POST', auth, body: data}))
				.then(response => {
					if (response.ok) return response.json(); else throw response.text();
				})
				.then(response => {
					if (response.result.length > 0) {
						this.sintomasSugeridos = response.result
						this.sugerirSintomaDialog = true
					} else {
						this.diagnosticar();
					}

				})
				.catch(e => {
					Promise.resolve(e).then(error => {
						if (JSON.parse(error).message === 'Unauthorized') {
							refresh_token();
							this.sugerirSintomas()
						}
					})
				})
				.finally(() => {
					cargando(false)
				})
		},
		createPDF() {
			const doc = new jsPDF();
			this.toDataURL(require('@/assets/images/logo.png'), imgData => {
				doc.addImage(imgData, 'JPEG', 150, 10, 40, 20)
				doc.setFontSize(20)
				doc.setTextColor(this.$vuetify.theme.currentTheme.primary)
				doc.text("Resultados preliminares", 10, 20);
				doc.setTextColor('#000')
				doc.setFontSize(14)
				doc.text([`Primer Nombre: ${this.informacion?.primerNombre} `], 10, 30, {maxWidth: 85})
				if (this.informacion?.segundoNombre) doc.text([`Segundo Nombre: `], 90, 30, {maxWidth: 180})
				doc.text([`Primer Apellido: ${this.informacion?.primerApellido} `], 10, 35, {maxWidth: 85})
				if (this.informacion?.segundoApellido) doc.text([`Segundo Nombre: `], 90, 35, {maxWidth: 180})

				doc.text([`Identificación: ${this.informacion?.tipoIdentificacion ?? ''} ${this.informacion?.identificacion} `], 10, 40, {maxWidth: 85})
				doc.text([`Fecha de evaluación: ${this.calcDate(this.evaluationDate)} `], 10, 45)

				let position = 55;

				doc.text([
					`Nivel de triage: ${this.mensajes[this.triage.triage_level].triage} `,
					`Nombre triage: ${this.mensajes[this.triage.triage_level].name} `,
					`Aplica teleconsulta: ${this.triage.teleconsultation_applicable ? "Si" : "No"} `,
					`Recomendación sistema: ${this.mensajes[this.triage.triage_level]?.todo} `
				], 10, position, {maxWidth: 180});
				position += 35;

				if (this.signosVitalesAnalizados) {
					doc.setTextColor(this.$vuetify.theme.currentTheme.primary)
					doc.setFontSize(14)
					doc.text("Signos Vitales", 10, position);
					doc.setTextColor('#000')
					doc.setFontSize(12)
					position += 7;

					doc.text([
						`Frecuencia cardíaca: ${this.signoVitales.frecuencia_cardiaca || 'N/D'}`,
						`Frecuencia respiratoria:  ${this.signoVitales.frecuencia_respiratoria || 'N/D'}`,
						`Sistólica:  ${this.signoVitales.sistolica || 'N/D'}`,
						`Peso:  ${this.signoVitales.peso || 'N/D'}`,
						`IMC:  ${this.signoVitales.imc || 'N/D'}`,
						`AVPU:  ${this.signoVitales.avpu || 'N/D'}`
					], 20, position);

					doc.text([
						`Temperatura:  ${this.signoVitales.temperatura || 'N/D'}`,
						`Saturación:  ${this.signoVitales.saturacion || 'N/D'}`,
						`Diastólica:  ${this.signoVitales.diastolica || 'N/D'}`,
						`Altura:  ${this.signoVitales.altura || 'N/D'}`,
						`Recursos:  ${this.signoVitales.recursos || 'N/D'}`
					], 100, position, {maxWidth: 180});

					position += 40;
				}
				doc.setTextColor(this.$vuetify.theme.currentTheme.primary)
				doc.setFontSize(14)
				doc.text("Síntomas", 10, position, {maxWidth: 180});
				doc.setTextColor('#000')
				doc.setFontSize(12)
				position += 10;
				this.filteredEvidence.forEach(evidence => {
					const symptom = this.items.find(i => i.id === evidence.id)
					if (symptom && evidence.id !== 'p_14') {
						const splitTitle = doc.splitTextToSize(`${evidence.source === 'initial' ? "* " : ''}${symptom.name}${symptom.common_name !== symptom.name ? ' - (' + symptom.common_name + ')' : ''} ${symptom.choice_id ? `- ${this.choiceIds[symptom.choice_id]}` : ''}`, 180);

						doc.text(splitTitle, 20, position);
						position += 5 * splitTitle.length;
					}
				})
				position += 5;
				doc.text("* Síntomas de consulta", 10, position, {maxWidth: 180});

				position += 15;

				if (this.pregunta) {
					doc.setTextColor(this.$vuetify.theme.currentTheme.primary)
					doc.setFontSize(14)
					doc.text("Posibles diagnósticos", 10, position, {maxWidth: 180});
					doc.setTextColor('#000')
					doc.setFontSize(12)
					position += 7;
					this.pregunta.conditions.forEach(dx => {
						doc.text([
							`${dx.name} ${dx.name !== dx.common_name ? `(${dx.common_name})` : ""}`,
							`Probabilidad: ${Math.floor(dx.probability * 100)}%`,
							`Recomendaciones: ${dx.recomendación || 'N/D'}`
						], 20, position, {maxWidth: 180});
						position += 20;
					})
				}

				if (!_.isEmpty(this.triage.serious)) {
					doc.setTextColor(this.$vuetify.theme.currentTheme.primary)
					doc.setFontSize(14)
					doc.text("Síntomas serios", 10, position, {maxWidth: 180});
					doc.setTextColor('#000')
					doc.setFontSize(12)
					position += 7;
					this.triage.serious.forEach(sint => {
						const splitTitle = doc.splitTextToSize(`${sint.name} ${sint.common_name && sint.name !== sint.common_name ? `(${sint.common_name})` : ""}`, 180);

						doc.text([
							...splitTitle,
							`Es emergencia: ${sint.is_emergency ? "Si" : "No"}`
						], 20, position)
						position += 15;
					})
				}

				if (this.especialista) {
					doc.setTextColor(this.$vuetify.theme.currentTheme.primary)
					doc.setFontSize(14)
					doc.text("Especialista recomendado", 10, position, {maxWidth: 180});
					doc.setTextColor('#000')
					doc.setFontSize(12)
					position += 7;
					doc.text([
						`${this.especialista.recommended_specialist.name}`,
						`Canal: ${this.getCanalEspecialista[this.especialista.recommended_channel]}`
					], 20, position, {maxWidth: 180})
					position += 15;
				}

				if (this.sugerenciaFinal) {
					doc.setTextColor(this.$vuetify.theme.currentTheme.primary)
					doc.setFontSize(14)
					doc.text("Sugerencia final al paciente", 10, position, {maxWidth: 180});
					doc.setTextColor('#000')
					doc.setFontSize(12)
					position += 7;
					const splitTitle = doc.splitTextToSize(`${this.sugerenciaFinal}`, 180)
					doc.text(splitTitle, 20, position, {maxWidth: 180})
				}


				var today = new Date();
				var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
				doc.save(`${this.informacion.identificacion}_${date}.pdf`);
			})
		},
		toDataURL(src, callback, outputFormat) {
			var img = new Image();
			img.crossOrigin = 'Anonymous';
			img.onload = function () {
				var canvas = document.createElement('CANVAS');
				var ctx = canvas.getContext('2d');
				canvas.height = this.naturalHeight;
				canvas.width = this.naturalWidth;
				ctx.drawImage(this, 0, 0);
				callback(canvas.toDataURL(outputFormat))

			};
			img.src = src;
			if (img.complete || img.complete === undefined) {
				img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
				img.src = src;
			}
		},
		buscar() {
			if (this.informacion.identificacion) {
				cargando(true);
				db.collection('interviews').where("user.identificacion", "==", this.informacion.identificacion).orderBy("date", "desc").onSnapshot(querySnapshot => {
					this.resultados = [];
					querySnapshot.forEach((doc) => {
						let temp = doc.data()
						temp.firebaseid = doc.id
						this.resultados.push(temp);
					})
					cargando(false);
				});
				this.consultaResultados = true

			}
		},
		buscarFolio(){
			console.log("")
			if (this.folio) {
				cargando(true);
				db.collection('interviews').where("sugerenciaFinal", "==", this.folio).orderBy("date", "desc").onSnapshot(querySnapshot => {
					this.resultados = [];
					querySnapshot.forEach((doc) => {
						let temp = doc.data()
						temp.firebaseid = doc.id
						this.resultados.push(temp);
					})
					cargando(false);
				});
				this.consultaResultados = true

			}
		},
		loadUserData(item) {
			this.nuevo();
			this[START_INTERVIEW]({
				firebaseId: item.firebaseid,
				entrevista: item.id,
				evidence: this.evidence,
				age: this.informacion.edad,
				sex: this.informacion.sexo,
			})
			this.evaluationDate = item.date
			if (item.evidence) {
				this[SET_EVIDENCE](item.evidence)
			}

			if (item.triage) {
				this.triageResult = true
				this.triage = item.triage
			}

			if (item.diagnosticos) {
				this.diagnosticos = item.diagnosticos;
				this.pregunta = {conditions: item.diagnosticos}
			}

			this.informacion = item.user
			if (item.user.nombre) {
				this.informacion.primerNombre = item.user.nombre
				delete this.informacion.nombre
			}
			if (item.user.apellidos) {
				this.informacion.primerApellido = item.user.apellidos
				delete this.informacion.apellidos
			}

			if (item.especialista) this.especialista = item.especialista
			if (item.signosVitales) {
				this.signoVitales = item.signosVitales
				this.signosVitalesAnalizados = true
			}
			if (item.signos_vitales) {
				this.signoVitales = item.signos_vitales
				this.signosVitalesAnalizados = true
			}
			this.terminada = item.terminada
			if (item.terminada) {
				this.sugerenciaFinal = item.sugerenciaFinal
			}

			this.consultaResultados = false;
		},
		calcDate(date) {
			if (date)
				return moment(date).format('DD MMMM YYYY - hh:mm a')
		},
		scanId() {
			this.scanIdDialog = true;
			document.addEventListener('keydown', this.handleScan)
		},
		handleScan(event) {
			event.preventDefault();
			event.stopPropagation();
			if (event.key === 'Enter') {
				this.handleBarCode()
			}
			if (event.key === 'Tab') {
				this.barcode += '|';
			} else if (event.key !== 'Shift') this.barcode += event.key;
		},
		handleBarCode() {
			document.removeEventListener('keydown', this.handleScan)
			const barcodeData = this.barcode.split('|')
			this.idCheck.identificacion = +barcodeData[0]
			this.idCheck.nombre = barcodeData[3] + ' ' + (barcodeData[4].length > 0 ? barcodeData[4] + ' ' : '') + barcodeData[1] + ' ' + barcodeData[2]
			this.idCheck.sexo = _.toLower(barcodeData[5])
			this.idCheck.edad = +moment().diff(barcodeData[6].substring(4, 8) + "-" + barcodeData[6].substring(2, 4) + "-" + barcodeData[6].substring(0, 2), 'years')
			this.confirmScanId()
		},
		confirmScanId() {
			this.informacion = _.cloneDeep(this.idCheck);
			this.scanIdDialog = false
		},
		cancelScan() {
			document.removeEventListener('keydown', this.handleScan)
			this.scanIdDialog = false
		},
		finalizar() {
			if (this.$refs.finalizarForm.validate()) {
				updateCollection('interviews', this.firebaseId, {
					sugerenciaFinal: this.sugerenciaFinal,
					terminada: true,
					adminCerrado: {id: this.$store.getters[types.USER_ID], name: this.$store.getters[types.USER]}
				}).then(() => {
					this.terminada = true;
					this.finalizarDialog = false
				})
			}
		},
		save(date) {
			this.$refs.menuFechaNacimiento.save(date)
			this.informacion.edad = moment().diff(date, 'years');
		},

		async enviar_urgencias() {
			console.log("aja")
			await updateCollection('interviews', this.firebaseId, {
				date: Date.now(),
				ubicacion: 'remoto',
				sede: this.selectedSede
			})
			await swal.fire({
				text: "Paciente actualizado para ser recibido en sala de urgencias",
				icon: "success"
			})
		}
	}

}


</script>

<style scoped>

</style>
